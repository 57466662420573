import React, { useState, useCallback } from 'react';
import { navigate, graphql } from 'gatsby';
import { Box, Button, Dialog, DialogTitle, IconButton, DialogContent, Typography, Link } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import SwitchAccountOutlinedIcon from '@mui/icons-material/SwitchAccountOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import SwipeableViews from 'react-swipeable-views';

import { LoginLayout } from '@components/layout';
import { useAuth } from '@context/auth';

import { StepperBar, StepOne, StepTwo, StepThree, StepFour, StepFive, StepSix } from '@components/register';

function Register({ data }) {
  const initialSteps = [
    {
      title: '',
      Icon: LockOutlinedIcon,
      completed: false,
      Page: StepOne,
      // result: { email: 'a@b.com', password: '123456', passwordConfirm: '123456' },
    },
    {
      title: 'Informações pessoais',
      Icon: PermIdentityOutlinedIcon,
      completed: false,
      Page: StepTwo,
      // result: {
      //   name: 'naruto uzumaki',
      //   cpf: '12345659812',
      //   phone: '11234567899',
      //   born: '30011980',
      //   gender: 'masculino',
      // },
    },
    { title: 'Tipo de conta', Icon: SwitchAccountOutlinedIcon, completed: false, Page: StepThree },
    { title: 'Informações profissionais', Icon: MedicalServicesOutlinedIcon, completed: false, Page: StepFour },
    {
      title: 'Endereço pessoal',
      Icon: PlaceOutlinedIcon,
      completed: false,
      Page: StepFive,
      // result: { cep: '06719460', number: '123' },
    },
    { title: 'Finalizar cadastro', Icon: CheckIcon, completed: false, Page: StepSix },
  ];
  const [steps, setSteps] = useState([...initialSteps]);

  const [currentStep, setCurrentStep] = useState(0);

  const [validating, setValidating] = useState(null);

  const [loading, setLoading] = useState(false);

  const [openError, setOpenError] = useState(false);

  const auth = useAuth();

  const success = useCallback(
    (values) => {
      setValidating(null);

      setSteps((old) => {
        const bkp = [...old];
        bkp[currentStep].result = { ...values };
        bkp[currentStep].completed = true;

        return [...bkp];
      });

      setCurrentStep((old) => {
        if (old === 2 && values.selected === 0) return old + 2;

        return old + 1;
      });
    },
    [currentStep]
  );

  const error = () => setValidating(null);

  const nextHandler = () => {
    setValidating({
      currentStep,
      success,
      error,
    });
  };

  const prevHandler = () => {
    setCurrentStep((old) => {
      if (old === 4 && steps[2].result.selected === 0) return old - 2;

      return old - 1;
    });
  };

  const submit = async (values) => {
    const data = [...steps];
    data[currentStep].result = { ...values };

    const dataFinal = data.reduce((start, current, index) => {
      if (index === 1) {
        return { ...start.result, ...current.result };
      }
      if (index > 1) {
        return { ...start, ...current.result };
      }
      return {};
    });

    setLoading(true);

    const localData = { ...dataFinal };
    delete localData.email;
    delete localData.password;
    delete localData.passwordConfirm;

    localStorage.setItem('CEMED_user', JSON.stringify(localData));

    const resp = await auth?.register(dataFinal);

    if (resp.error) {
      // openMessage({ message: 'Falha no envio. Tente novamente mais tarde.' });
      setOpenError(true);
      setLoading(false);
      return;
    }

    navigate('/needVerify');
    setLoading(false);
  };

  const centralHandler = (e) => {
    e.preventDefault();
    navigate('/central_de_ajuda');
  };

  const closeErrorHandler = () => {
    setOpenError(false);
  };

  return (
    <>
      <StepperBar data={steps} current={currentStep} />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <Box
            sx={{
              height: 'calc(100% - 86px)',
              maxWidth: '630px',
              width: '100%',
              mx: 'auto',
              my: '1rem',
              pt: currentStep > 0 ? '1.5rem' : '0',
              '& .swipeable-container': {
                height: '100%',
                '& .react-swipeable-view-container': { height: '100%' },
              },
            }}
          >
            <SwipeableViews index={currentStep} className="swipeable-container" disabled>
              {steps.map((step, index) => (
                <step.Page
                  key={`${step.title}-${index}`}
                  data={step}
                  validating={validating}
                  submit={submit}
                  loading={loading}
                  imageData={data}
                />
              ))}
            </SwipeableViews>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', borderTop: '1px solid var(--gray-2)', py: '0.5rem' }}>
            {currentStep === 0 && (
              <Button onClick={() => navigate('/')}>
                <ArrowRightAltIcon sx={{ transform: 'scale(-1)' }} />
                voltar
              </Button>
            )}
            {currentStep > 0 && (
              <Button onClick={prevHandler}>
                <ArrowRightAltIcon sx={{ transform: 'scale(-1)' }} />
                anterior
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button sx={{ ml: 'auto' }} onClick={nextHandler}>
                próximo
                <ArrowRightAltIcon />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Dialog open={openError} onClose={closeErrorHandler}>
        <DialogTitle sx={{ display: 'flex' }}>
          <IconButton sx={{ ml: 'auto' }} onClick={closeErrorHandler}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '0.8rem',
            '& a': { color: 'var(--gray-3)', textDecorationColor: 'var(--gray-3)' },
          }}
        >
          <Typography variant="body1" color="error" align="center">
            Seu cadastro não pode ser concluído, verifique as informações e tente novamente.
          </Typography>

          <Typography variant="body1">
            Qualquer dúvida acesse nossa{' '}
            <Link href="#" onClick={centralHandler}>
              Central de Ajuda
            </Link>
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}

function Index(props) {
  return (
    <LoginLayout>
      <Register {...props} />
    </LoginLayout>
  );
}

export const registerQuery = graphql`
  {
    allFile(filter: { relativePath: { glob: "register/*.png" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
  }
`;

export default Index;
